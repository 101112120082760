<template>
  <div>
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <h1><b-icon icon="calendar2-week" /> Ciclos</h1>
        </div>
        <div class="card-body">
          <div class="container col-12">
            <div>
              <b-table
                striped
                responsive
                stacked="lg"
                :items="desserts"
                :fields="fields"
              >
                <template #cell(state)="row">
                  <b-badge pill :variant="returnColor(row.item.state)">{{
                    returnData(row.item.state)
                  }}</b-badge>
                </template>
                <template #cell(people)="row">
                  <b-icon
                    type="button"
                    @click="toPeople(row.item.id)"
                    icon="people"
                  />
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BIcon, BIconArrowUp, BIconArrowDown } from "bootstrap-vue";
export default {
  components: { BIcon, BIconArrowUp, BIconArrowDown },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    data: {
      id: -1,
      index: "",
      description: "",
      dateOpening: "",
      dateClosing: "",
      state: "",
    },
    fields: [
      { key: "description", label: "Nombre descriptivo" },
      { key: "dateOpening", label: "Fecha Inicio" },
      { key: "dateClosing", label: "Fecha Fin" },
      { key: "state", label: "Estado" },
      { key: "people", label: "Personas" },
      //{ key: "actions", label: "Acciones" },
    ],
    desserts: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    toPeople(data) {
      this.$router.push("/people/" + data);
    },
    returnData(data) {
      if (data === "A") return "Activo";
    },
    returnColor(data) {
      if (data === "A") return "success";
    },
    async initialize() {
      let res = await this.$axios
        .get("/academic/program-period/"+ this.id, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
      var aux = [];
      for (var i in res.data.programPeriods) {
        aux.push(res.data.programPeriods[i]);
      }
      this.desserts = aux;
    },
  },
};
</script>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
</style>